/**
 * CONTENTS
 *
 * Icon fonts
 *
 * Standard tags
 *
 * Footer
 *
 * Header
 *
 * Article
 *
 * Pagination
 *
 * Author
 *
 * Code, syntax hilighting
 *
 * Inline images
 *
 * Docutils specifics
 *
 * Media rules
 *
 */



/*----------------------------------------*\
 * Icons font
\*----------------------------------------*/

/**
 * Copied from icomoon and edited for style
 */

@font-face {
  font-family: 'icons';
  src:  url('fonts/icons.eot?nfrjwz');
  src:  url('fonts/icons.eot?nfrjwz#iefix') format('embedded-opentype'),
    url('fonts/icons.ttf?nfrjwz') format('truetype'),
    url('fonts/icons.woff?nfrjwz') format('woff'),
    url('fonts/icons.svg?nfrjwz#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before           { content: "\e900"; }
.icon-folder:before         { content: "\e92f"; }
.icon-folder-open:before    { content: "\e930"; }
.icon-price-tag:before      { content: "\e935"; }
.icon-price-tags:before     { content: "\e936"; }
.icon-location:before       { content: "\e947"; }
.icon-briefcase:before      { content: "\e9ae"; }
.icon-menu:before           { content: "\e9bd"; }
.icon-earth:before          { content: "\e9ca"; }
.icon-link:before           { content: "\e9cb"; }
.icon-info:before           { content: "\ea0c"; }
.icon-facebook2:before      { content: "\ea91"; }
.icon-twitter:before        { content: "\ea96"; }
.icon-rss2:before           { content: "\ea9c"; }
.icon-youtube:before        { content: "\ea9d"; }
.icon-github:before         { content: "\eab0"; }
.icon-reddit:before         { content: "\eac6"; }
.icon-linkedin:before       { content: "\eac9"; }
.icon-stackoverflow:before  { content: "\ead0"; }
.icon-git:before            { content: "\eae7"; }
.icon-file-text2:before     { content: "\e926"; }

/* icon renaming for use in templates */

.icon-categories:before     { content: "\e92f"; }
.icon-category:before       { content: "\e930"; }
.icon-tag:before            { content: "\e935"; }
.icon-tags:before           { content: "\e936"; }
.icon-archives:before       { content: "\e9ae"; }
.icon-about:before          { content: "\ea0c"; }
.icon-facebook:before       { content: "\ea91"; }
.icon-gitlab:before         { content: "\eae7"; }



/*----------------------------------------*\
 * Standard tags
\*----------------------------------------*/

body {
  font: 400 1em / 1em 'cardo', serif;
  text-rendering: optimizelegibility;
  color: #444;
	line-height: 1.7em;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'fira sans', sans-serif;
  text-rendering: optimizelegibility;
  color: #222;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0.5em auto;
}

code {
	font-size: 80%;
}

main {
	position: relative;
  max-width: 36em;
  margin: 0 auto;
	padding: 2em 0;
}

table {
  font-family: 'fira sans', sans-serif;
	font-size: 90%;
	min-width: 60%;
	margin: 0 auto;
	line-height: 1.8em;
  border-collapse: collapse;
  border-top: 0;
  border-bottom: solid 1 px #444;
  text-align: left;
}

table th {
	border-bottom: solid 1px #444;
}

table tr {
  border-bottom: solid 1px #ccc;
}

table tr:hover {
  background-color: #eee;
}

table th,
table td {
  padding: 5px 15px;
}

table .right {
  text-align: right;
}


/*----------------------------------------*\
 * Main container
\*----------------------------------------*/

.page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.page-main { flex: 1; }



/*----------------------------------------*\
 * Footer
\*----------------------------------------*/

.page-footer {
  display: flex;
  flex-direction: column;
  order: 99;
  font-family: 'fira sans', sans-serif;
  line-height: 1em;
	margin-top: 2em;
  padding: 0;
  background: no-repeat scroll center center/cover #002b36;
}

.page-footer nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
  flex: 1;
	font-size: 0.8em;
	line-height: 1.5em;
	color: #ddd;
}

.page-footer nav ul {
	list-style-type: none;
  padding: 0;
}

.page-footer nav ul a {
	color: inherit;
	text-decoration: none;
}

.page-footer nav ul a:hover,
.page-footer nav ul a:focus {
	color: inherit;
	text-decoration: underline;
}

.credits {
	color: #aaa;
  text-align: right;
  font-size: 0.625em;
  line-height: 1em;
  padding: 1em 2em;
}

.credits a {
  color: inherit;
}

/*----------------------------------------*\
 * Header
\*----------------------------------------*/

.page-header {
  display: flex;
  flex-direction: column;
  flex: 0;
  order: 0;
  text-align: center;
  font-family: 'fira sans', sans-serif;
  padding: 1em 0 2em 0;
  background: no-repeat scroll center center/cover #002b36;
}

.page-title {
  font-weight: 700;
  font-size: 1.6em;
  margin: 2em 0 0 0;
  text-decoration: none;
  color: #fff;
}

.page-title a {
  text-decoration: none;
  font-weight: inherit;
  color: inherit;
}

.page-subtitle {
  font-weight: 500;
  color: #fff;
  margin-top: 0.6em;
}

.page-subtitle a {
  color: inherit;
  text-decoration: inherit;
}

.page-subtitle a:hover,
.page-subtitle a:focus {
	text-decoration: underline;
}

.nav-button {
  position: absolute;
  top: 0;
  margin: 1em;
  z-index: 200;
}

.nav-button#home-button {
  left: 0;
}

.nav-button#menu-button {
  right: 0;
}

.nav-button a {
  display: block;
  height: 1.4em;
  margin-bottom: 1em;
  padding: 0.5em 1.25em;
  border: 1px solid #ddd;
  font-size: 0.75em;
  line-height: 1.666em;
  text-shadow: none;
  color: #ddd;
  cursor: pointer;
  background: #002b36;
  border-radius: 1em;
	text-decoration: none;
}

.nav-button a:hover,
.nav-button a:focus {
	text-decoration: underline;
}



/*----------------------------------------*\
 * Article
\*----------------------------------------*/

.post,
.archive {
  position: relative;
  z-index: 10;
  padding: 0.5em 16px;
}

.post hr {
	margin: 2em 0 0.5em 0;
	color: #aaa;
}

/* article header */

.post-title {
  position: relative;
  z-index: 10;
  margin: 0 0 0.5em;
}

h1.post-title {
  color: #fff;
	margin: 0.3em 0;
  font-size: 1.6em;
}

h2.post-title {
  font-size: 1.6em;
  line-height: 1.2em;
  font-weight: 600;
}

h3.post-title {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 500;
}

.post-title a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
}

.post-title a:hover,
.post-title a:focus {
  text-decoration: underline;
  color: inherit;
  font-weight: inherit;
}

/* article metainfo */

.post-meta,
.post-tags {
  position: relative;
  z-index: 10;
  display: block;
  margin-bottom: 1.333em;
  font-family: 'fira sans', sans-serif;
  font-size: 0.75em;
  font-weight: 400;
  line-height: 1.333em;
	color: #aaa;
}

.archive .post-meta {
	margin-bottom: 0;
}

.post-meta a,
.post-tags a {
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid transparent;
}

.post-meta a:hover,
.post-meta a:focus,
.post-tags a:hover,
.post-tags a:focus {
  border-color: #aaa;
}

/* article content */

.post-content a {
	text-decoration: none;
  border-bottom: 1px solid #f26a3d;
	color: inherit;
}

.post-content a:hover,
.post-content a:focus {
  color: #f26a3d;
}



/*----------------------------------------*\
 * Pagination
\*----------------------------------------*/

.pagination {
  position: absolute;
  bottom: 0;
  margin: 1em auto;
	padding: 0 1.25em;
  z-index: 200;
	max-width: 40em;
  height: 1em;
  font-family: 'fira sans', sans-serif;
  font-size: 0.8em;
  line-height: 1em;
}

.pagination a {
  text-decoration: none;
  color: inherit;
}

#pagination-info {
  position: absolute;
  bottom: 0;
	width: 100%;
  height: 1em;
  font-family: 'fira sans', sans-serif;
  font-size: 0.8em;
  line-height: 1em;
  margin: 1em auto;
	text-align: center;
}

.pagination#pagination-prev {
	text-align: right;
	right: 0;
}

.pagination#pagination-next {
	text-align: left;
	left: 0;
}

.pagination a:hover,
.pagination a:focus {
	text-decoration: underline;
}



/*----------------------------------------*\
 * Author
\*----------------------------------------*/

.post-author {
  position: relative;
  margin: 0;
  padding: 1em 0;
	min-height: 3em;
  z-index: 10;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.post-author-avatar {
  position: relative;
  float: left;
  width: 14%;
  margin: auto 5%;
  overflow: hidden;
  background: #fff;
  border-radius: 7em;
  box-sizing: border-box;
}

.post-author-avatar img {
  width: 100%;
  margin: auto;
  border-radius: 7em;
  box-sizing: border-box;
}

.post-author-bio {
  margin-left: 25%;
  font-family: 'fira sans', sans-serif;
  font-size: 0.75em;
  line-height: 1.333em
}

.post-author-bio a {
	color: inherit;
	text-decoration: none;
}

.post-author-bio a:hover,
.post-author-bio a:focus {
	text-decoration: underline;
}

.post-author-name {
	font-weight: bold;
}



/*----------------------------------------*\
 * Code, syntax hilighting
\*----------------------------------------*/

.highlight {
  border-left: 1px solid #f26a3d;
  background: #fdf6e3;
  overflow-x: auto;
}

.highlight pre,
.linenodiv pre {
	margin: 0;
	padding: 0.5em;
	font-size: 70%;
	line-height: 1.4em;
}



/*----------------------------------------*\
 * Docutils specifics
\*----------------------------------------*/

.admonition {
  font-family: 'fira sans', sans-serif;
  text-rendering: optimizelegibility;
  font-size: 85%;
  border-left: 2px solid #ccc;
  padding: 0 0 0.5em 0;
  margin: 0.5em 0;
}

.admonition .first,
.admonition .last {
  padding: 0.2em 1em;
}

.figure { margin: 2em auto; }

.caption {
  text-align: center;
  font-family: 'fira sans', sans-serif;
  font-size: 75%;
  margin: 0;
}

.strike { text-decoration: line-through; }

.note { background: #ccffff; }

.admonition.note .first {
  background: #99cccc;
  font-weight: 600;
  margin: 0;
}

.warning { background: #ffdfbf; }

.admonition.warning .first {
  background: #db4b26;
  margin: 0;
}

.admonition-title { font-weight: 500; }

table.footnote {
  font-family: 'fira sans', sans-serif;
	font-size: 80%;
	min-width: 100%;
	margin: 0.5em auto 2em;
	line-height: 1.8em;
  border-collapse: collapse;
  border: none;
  text-align: left;
}

table.footnote tr {
  border-bottom: 0;
}

table.footnote tr:hover {
  background-color: transparent;
}

table.footnote td { padding: 5px; }

td.label { width: 2em; }

p.rubric {
  font-family: 'fira sans', sans-serif;
  font-size: 1.2em;
  text-rendering: optimizelegibility;
  color: #222;
}



/*----------------------------------------*\
 * Inline images
\*----------------------------------------*/

.inlineimg {
	display: inline;
	margin: 0;
	padding: 0;
}



/*----------------------------------------*\
 * Media rules
\*----------------------------------------*/

@media only screen and (min-width: 480px) {
	h1.page-title {
  	font-size: 2em;
    line-height: 2rem;
	}
  .post,
  .archive	{
   	padding: 1em 1em 0 1em;
    }
	.post-author {
		min-height: 5em;
	}
}

@media only screen and (min-width: 640px) {
  html {
		font-size: 1.15em;
  }
	h1.page-title {
		font-size: 2.2em;
    line-height: 2.2rem;
	}
}

